import Axios, { AxiosInstance } from 'axios';
import { MOPSPOT_API_URL } from './config';

const buildHttpClient = ({ baseUrl = '' }): AxiosInstance => {
  const httpClient = Axios.create({
    baseURL: baseUrl
  });

  httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: any) => {
      if (error.response?.status !== 401) {
        const message = error.response?.data?.message || error.message;
        alert("We're sorry, there was an error: " + message);
      } else {
        window.location.href = '/authentication/login';
      }
      return Promise.reject(error);
    }
  );

  return httpClient;
};

export const axiosClient = buildHttpClient({
  baseUrl: MOPSPOT_API_URL
});

export const getAxios = () => {
  return axiosClient;
};
