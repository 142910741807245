import { Text } from '@chakra-ui/react';
export const MopspotText = () => {
  return (
    <span>
      <Text display={'inline'} fontWeight='bold'>
        Mop
      </Text>
      <Text display={'inline'} fontWeight='medium'>
        Spot
      </Text>
    </span>
  );
};
