import { yupResolver } from '@hookform/resolvers/yup';
import { PropsWithChildren } from 'react';
import { FieldValues, FormProvider, UseFormReturn, useForm } from 'react-hook-form';
import { useWizardState } from '../../features/funnel/store/WizardStore';
import { WizardStep } from '../../lib/WizardGenerator/models/WizardDefinition';
import { JsonViewer } from '../misc/JsonView';
import WizardNavigation from './wizardNavigation';

type WizardFormContainerProps = {
  onSubmit: () => void;
  onStepForward?: (exitingStep: WizardStep, newStep: WizardStep | undefined, formData: any, formDataAccessor: UseFormReturn<FieldValues, any, undefined>) => Promise<any>;
  onStepBackward?: (formData: any, formDataAccessor: UseFormReturn<FieldValues, any, undefined>) => Promise<any>;
  stepDefinition: WizardStep;
  steps: WizardStep[];
  defaultData: any;
};

export const WizardFormContainer = (props: PropsWithChildren<WizardFormContainerProps>) => {
  const methods = useForm({
    resolver: yupResolver(props.stepDefinition.ValidationSchema),
    mode: 'all',
    defaultValues: props.defaultData
  });

  const wizardStore = useWizardState();
  return (
    <FormProvider {...methods}>
      {location.hostname === 'localhost' && <JsonViewer object={methods.getValues()} />}
      <form key={wizardStore.currentStep}>{props.children}</form>
      <WizardNavigation steps={props.steps} onSubmit={props.onSubmit} onStepForward={props.onStepForward} onStepBackward={props.onStepBackward} />
    </FormProvider>
  );
};
