import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { Box, Flex, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputFieldProps } from '../InputFieldsProps';

type QuantitySelectorProps = InputFieldProps<string> & {
  selected: boolean | undefined;
  notSelectable?: boolean | undefined;
  min?: number;
  max?: number;
  defaultAmount?: number;
  onQuantityChange: (newQuantity: number) => void;
};

export const QuantitySelectorField = (props: QuantitySelectorProps) => {
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const methods = useFormContext();

  useEffect(() => {
    const value = methods.getValues(props.name);
    //if we have a value and are selected, use it
    if (value !== undefined && value !== 0 && (props.selected || props.notSelectable)) {
      setQuantity(value);
    } else if ((value === undefined || value === 0) && (props.selected || props.notSelectable)) {
      setQuantity(props.defaultAmount !== undefined ? props.defaultAmount : 1);
    } else {
      setQuantity(0);
    }
  }, [props.selected]);

  const setQuantity = (quantity: number) => {
    setCurrentQuantity(quantity);
    methods.setValue(props.name, quantity);
    props.onQuantityChange(quantity);
  };

  const onIncreaseQuantity = useCallback(() => {
    if (props.max !== undefined && currentQuantity >= props.max) {
      return;
    }
    setQuantity(currentQuantity + 1);
  }, [currentQuantity]);

  const onDecreaseQuantity = useCallback(() => {
    if (props.min !== undefined && currentQuantity <= props.min) {
      return;
    }
    setQuantity(currentQuantity - 1);
  }, [currentQuantity]);

  return (
    <Flex gap={3} justifyContent={'center'} alignItems={'center'} paddingBottom={'10px'}>
      <Box cursor={'pointer'} backgroundColor={'green.100'} borderRadius={100} onClick={onDecreaseQuantity}>
        <MinusIcon margin={'6px'} color={'white'} boxSize={3} />
      </Box>
      <Text>{currentQuantity}</Text>
      <Box cursor={'pointer'} backgroundColor={'green.100'} borderRadius={100} onClick={onIncreaseQuantity}>
        <AddIcon margin={'6px'} color={'white'} boxSize={3} />
      </Box>
    </Flex>
  );
};
