import { Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

type HiddenFieldProps = {
  name: string;
  value?: string | number;
};

export const HiddenField = (props: HiddenFieldProps) => {
  const methods = useFormContext();
  return <Input type='hidden' {...methods.register(props.name)} value={props.value} />;
};
