import { getEntityDesignerAxios } from '../../entityDesignerAxios';
import { EntityModel } from '../models/EntityModel';

export const saveEntity = async (model: EntityModel): Promise<EntityModel> => {
  const axios = getEntityDesignerAxios();
  let response: EntityModel;
  if (model.id) {
    response = (await axios.put<EntityModel>(`/entity`, model)).data;
  } else {
    response = (await axios.post<EntityModel>(`/entity`, model)).data;
  }
  return response;
};
