import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Heading } from '@chakra-ui/react';
import { PriceRange } from '../../../../../lib/Calculator/Models/PriceRange';
import { QuotePrice } from './QuotePrice';

type QuoteTotalLineProps = {
  heading: string;
  priceRange: PriceRange;
  color: string;
  isCollapsed: boolean;
  onToggleCollapse: () => void;
};

export const QuoteTotalLine = ({ heading, priceRange, color, isCollapsed, onToggleCollapse }: QuoteTotalLineProps) => {
  const lineColor = `${color}.100`;
  const borderRadius = isCollapsed ? '4px' : '0px 0px 4px 4px';
  return (
    <Box
      onClick={() => {
        onToggleCollapse();
      }}
      cursor={isCollapsed ? 'pointer' : ''}
      backgroundColor={lineColor}
      color={'white'}
      padding={'15px'}
      borderRadius={borderRadius}>
      <Flex justifyContent={'space-between'} alignContent={'center'}>
        <Heading size={['xs', 'xs', 'xs', 'md']}>{heading}</Heading>
        <HStack gap={0}>
          <QuotePrice size={['xs', 'xs', 'xs', 'md']} startingPrice={priceRange.startingPrice} endingPrice={priceRange.endingPrice} />
          {isCollapsed && <ChevronDownIcon boxSize={{ base: 5, lg: 7 }} />}
          {!isCollapsed && <ChevronUpIcon boxSize={{ base: 5, lg: 7 }} />}
        </HStack>
      </Flex>
    </Box>
  );
};
