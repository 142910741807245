import { Select } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { EntityPropertyListValueModel } from '../../lib/EntityDesigner/models/EntityPropertyListValueModel';
import { InputFieldProps } from './InputFieldsProps';

type SelectFieldProps = InputFieldProps<string> & {
  items: Array<EntityPropertyListValueModel>;
};

export const SelectField = (props: SelectFieldProps) => {
  const methods = useFormContext();

  return (
    <Select
      disabled={methods.formState.isSubmitting}
      {...methods.register(props.name, {
        valueAsNumber: props.isNumeric ?? false
      })}
      borderTopLeftRadius={0}
      borderBottomLeftRadius={0}
      defaultValue={props.defaultValue}>
      {props.items.map((item: EntityPropertyListValueModel, index) => (
        <option key={index} value={item.displayName}>
          {item.displayName}
        </option>
      ))}
    </Select>
  );
};
