import { Box, VStack } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { useWizardState } from '../../features/funnel/store/WizardStore';
import { ProgressStepModel } from '../../lib/WizardGenerator/models/WizardDefinition';
import { StepContainer } from './types/wizardStep';
import WizardProgress from './wizardProgress';

type WizardProps = {
  steps: Array<StepContainer>;
  progressSteps: Array<ProgressStepModel>;
};

const Wizard = ({ steps, progressSteps }: WizardProps) => {
  const wizardStore = useWizardState();

  return (
    <VStack w={'100%'}>
      <WizardProgress currentStep={steps[wizardStore.currentStep].ProgressStepIndex} wizardSteps={progressSteps} />
      <Box w={['100%', '100%', '80%', '80%', '80%', '60%']} paddingTop={['60px', '60px', '50px']}>
        <AnimatePresence>{steps[wizardStore.currentStep].Component}</AnimatePresence>
      </Box>
    </VStack>
  );
};

export default Wizard;
