import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type StepData = {
  isValid: boolean;
  formData: any;
};

export type WizardState = {
  formData: any;
  currentStep: number;
  currentProgressStep: number;
  totalSteps: number;
  setFormData: (data: any) => void;
  forward: () => void;
  backward: () => void;
  setTotalSteps: (totalSteps: number) => void;
};

export const useWizardState = create<WizardState>()(
  devtools((set) => ({
    totalSteps: 0,
    currentProgressStep: 0,
    formData: {},
    currentStep: 0,
    setFormData: (data) =>
      set(() => ({
        formData: data
      })),
    forward: () =>
      set((state) => ({
        currentStep: state.currentStep + 1
      })),
    backward: () =>
      set((state) => ({
        currentStep: state.currentStep - 1
      })),
    setTotalSteps: (totalSteps: number) =>
      set(() => ({
        totalSteps: totalSteps
      }))
  }))
);
