// themes/stepper.ts

const baseStyle = {
  // // select the indicator part
  // indicator: {
  //   // change the default border radius to 0
  //   borderColor: "purple.100",
  // },
  // separator: { borderColor: "purple.100", backgroundColor: "purple.100" },
};

export const StepperTheme = {
  baseStyle
};
