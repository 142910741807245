import { Flex, FormControl } from '@chakra-ui/react';
import { IconCheckboxQuantityField } from '../../../../components/forms/IconInputs/IconCheckboxQuantityField.tsx';
import { LoadingSpinner } from '../../../../components/misc/LoadingSpinner.tsx';
import { useAllEntitiesQuery } from '../../../../lib/EntityDesigner/services/getEntities';
import { IconCheckboxQuantityConfig } from '../../../../lib/WizardGenerator/models/WizardDefinition.tsx';
import { AddonIconMappings } from '../../FunnelDefinition.tsx';

type AddonFormProps = {
  onAddonChange: () => void;
  onAddonRemoved: (key: string) => void;
};

export const AddonForm = (props: AddonFormProps) => {
  const addonQuery = useAllEntitiesQuery('CleaningAddonDefinition');

  if (addonQuery.isLoading || !addonQuery.data) {
    return <LoadingSpinner />;
  }

  return (
    <FormControl>
      <Flex gap={[0, 0, 0, 4]} justifyContent={'space-between'} flexWrap={'wrap'}>
        {addonQuery.data.map((addon, index) => {
          const config: IconCheckboxQuantityConfig = {
            Icon: {
              DisplayText: addon.data.Name,
              Icon: AddonIconMappings[addon.data.Key as keyof typeof AddonIconMappings]
            },
            Min: 0,
            Max: 10,
            QuantityName: `Contact.CleaningQuote.CleaningQuoteAddons[${index}].Quantity`,
            SelectedValue: addon.data.Key,
            ShowQuantity: addon.data.ShowQuantity
          };
          return (
            <IconCheckboxQuantityField
              onSelectedChange={(newValue) => {
                if (!addon.data.ShowQuantity) {
                  props.onAddonChange();
                }
                if (!newValue) {
                  props.onAddonRemoved(addon.data.Key);
                }
              }}
              onQuantityChange={() => {
                if (addon.data.ShowQuantity) {
                  props.onAddonChange();
                }
              }}
              initialQuantity={1}
              showAddIcons={true}
              name={`Contact.CleaningQuote.CleaningQuoteAddons[${index}]`}
              itemKey={addon.data.Key}
              key={index}
              config={config}
            />
          );
        })}
      </Flex>
    </FormControl>
  );
};
