import { useRoutes } from 'react-router-dom';
import { App } from './app';
import { Calculator } from './features/calculator/Calculator';
import Funnel from './features/funnel/Funnel';
import { FunnelComplete } from './features/funnel/FunnelComplete';

const protectedRoutes = [
  {
    path: '/',
    element: <App />,
    children: [
      { path: '/', element: <Funnel /> },
      { path: '/thankyou', element: <FunnelComplete /> },
      { path: '/calculator', element: <Calculator /> }
    ]
  }
];

export const AppRoutes = () => {
  const element = useRoutes([...protectedRoutes]);

  return <>{element}</>;
};
