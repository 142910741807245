import { QuestionIcon } from '@chakra-ui/icons';
import { Popover, PopoverBody, PopoverCloseButton, PopoverContent, PopoverTrigger } from '@chakra-ui/react';

type FormHelpTextProps = {
  text: string;
};

export const FormHelpText = (props: FormHelpTextProps) => {
  return (
    <Popover trigger='hover'>
      <PopoverTrigger>
        <QuestionIcon cursor={'pointer'} color={'blue.100'} />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverCloseButton />
        <PopoverBody>
          <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
