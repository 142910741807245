import * as yup from 'yup';
import { PropertyDisplayType } from '../../enums/form/PropertyDisplayType';
import { IconConfig, IconQuantityConfig, WizardDefinition } from '../../lib/WizardGenerator/models/WizardDefinition';
import {
  Blinds,
  Carpet,
  CeilingFan,
  Chandelier,
  ChangeBedding,
  CouchVacuum,
  DustHighReachSpots,
  FourPieceBath,
  Fridge,
  GasRange,
  GlassRailings,
  GlassShower,
  GlassWalls,
  HalfBath,
  HardSurfaceLevels,
  InsideOven,
  InteriorWindows,
  Kid,
  LaunderBedding,
  LoadUnloadDishwasher,
  OversizedRangeHood,
  Pets,
  ThreePieceBath,
  WetBar
} from '../funnel/Icons';

export const AddonIconMappings = {
  OvenCleaning: InsideOven,
  InteriorWindows: InteriorWindows,
  Blinds: Blinds,
  'Dusting High Reach Spots': DustHighReachSpots,
  UnloadLoadDishwasher: LoadUnloadDishwasher,
  FridgeClean: Fridge,
  LaunderBedding: LaunderBedding,
  ChangeBedding: ChangeBedding,
  CeilingFanDusting: CeilingFan,
  CouchVacuum: CouchVacuum,
  WetBar: WetBar
};

const flooringIconTypes: { [key: string]: IconConfig } = {
  'Hard Surfaces': { Icon: HardSurfaceLevels, DisplayText: 'Hard Surfaces' },
  Carpet: { Icon: Carpet, DisplayText: 'Carpet' }
};

const halfBathConfig: IconQuantityConfig = {
  Icon: { Icon: HalfBath, DisplayText: 'Half Bath' },
  min: 0,
  max: 10
};

const glassShowerConfig: IconQuantityConfig = {
  Icon: { Icon: GlassShower, DisplayText: 'Glass Showers' },
  min: 0,
  max: 10
};

const threePieceBathConfig: IconQuantityConfig = {
  Icon: { Icon: ThreePieceBath, DisplayText: '3 Piece Bath' },
  min: 0,
  max: 10
};

const fourPieceBathConfig: IconQuantityConfig = {
  Icon: { Icon: FourPieceBath, DisplayText: '4 Piece Bath' },
  min: 0,
  max: 10
};

const petConfig: IconQuantityConfig = {
  Icon: { Icon: Pets, DisplayText: 'Furry Pets' },
  min: 0,
  max: 10
};

const kidConfig: IconQuantityConfig = {
  Icon: { Icon: Kid, DisplayText: 'Kids' },
  min: 0,
  max: 10
};

export const CalculatorDefinition: WizardDefinition = {
  ProgressSteps: [],
  defaultData: {},
  Steps: [
    {
      Header: 'Calculator Input',
      DealStageId: '138404262',
      DevDealStageId: '138404262',
      Subheader: '',
      StepName: 'About You',
      ProgressStepIndex: 0,
      Questions: [
        {
          Key: 'Contact.CleaningQuote.ServiceInterval',
          Label: 'Type of Service'
        },
        {
          Key: 'Contact.CleaningQuote.PropertyType',
          Label: 'What type of home do you have?'
        },
        {
          Key: 'Contact.CleaningQuote.PropertySqft',
          Label: 'What is the square footage of your home (excluding the basement)?',
          NumericMin: 500,
          NumericMax: 10000,
          NumericStep: 100
        },
        {
          Key: 'Contact.CleaningQuote.PropertyLevels',
          Label: 'How many levels does your home have?',
          NumericMin: 1,
          NumericMax: 10,
          NumericStep: 1,
          HideIfProperty: 'Contact.CleaningQuote.PropertyType',
          HideIfValue: ['Bungalow', 'Two Storey', 'Three Storey', 'Three Level Split', 'Four Level Split']
        },
        {
          Key: 'Contact.CleaningQuote.IncludeBasement',
          Label: 'Would you like the basement cleaned?',
          HideIfProperty: 'Contact.CleaningQuote.PropertyType',
          HideIfValue: ['Condo Or Apartment']
        },
        {
          Key: 'Contact.CleaningQuote.BasementFlooringType',
          Label: 'Is your basement hard surface flooring or fully carpeted?',
          PropertyDisplayType: PropertyDisplayType.IconRadio,
          PropertyDisplayTypeConfig: { IconValueMapping: flooringIconTypes },
          HideIfProperty: 'Contact.CleaningQuote.IncludeBasement',
          HideIfValue: [false]
        },
        {
          Key: 'Contact.CleaningQuote.FourPieceBathroomCount',
          Label: 'How many bathrooms do you have?',
          PropertyDisplayType: PropertyDisplayType.IconQuantity,
          PropertyDisplayTypeConfig: fourPieceBathConfig,
          RenderWith: [
            {
              Key: 'Contact.CleaningQuote.ThreePieceBathroomCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: threePieceBathConfig
            },
            {
              Key: 'Contact.CleaningQuote.HalfBathroomCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: halfBathConfig
            },
            {
              Key: 'Contact.CleaningQuote.GlassShowerCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: glassShowerConfig
            }
          ]
        },

        {
          Key: 'Contact.CleaningQuote.FlooringType',
          Label: 'Are your bedrooms hard surface flooring or fully carpeted?',
          PropertyDisplayType: PropertyDisplayType.IconRadio,
          PropertyDisplayTypeConfig: { IconValueMapping: flooringIconTypes }
        },
        {
          Key: 'Contact.CleaningQuote.PetCount',
          Label: 'How many munchkins do you have?',
          PropertyDisplayType: PropertyDisplayType.IconQuantity,
          PropertyDisplayTypeConfig: petConfig,
          RenderWith: [
            {
              Key: 'Contact.CleaningQuote.KidCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: kidConfig
            }
          ]
        },

        {
          Key: 'Contact.CleaningQuote.HasGlassWallsDoors',
          PropertyDisplayType: PropertyDisplayType.IconCheckbox,
          PropertyDisplayTypeConfig: { Icon: { Icon: GlassWalls, DisplayText: 'Glass Walls/Doors' } },
          RenderWith: [
            {
              Key: 'Contact.CleaningQuote.HasOversizedChandeliers',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: Chandelier, DisplayText: 'Hanging Light Fixtures' } }
            },
            {
              Key: 'Contact.CleaningQuote.HasOversizedHoodFan',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: OversizedRangeHood, DisplayText: 'Oversized Hood Fan' } }
            },
            {
              Key: 'Contact.CleaningQuote.HasProfessionalGasRange',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: GasRange, DisplayText: 'Pro. Gas Range' } }
            },
            {
              Key: 'Contact.CleaningQuote.HasGlassRailings',
              Label: 'Does your home have any of these special features?',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: GlassRailings, DisplayText: 'Glass Railings' } }
            }
          ]
        }
      ],
      ValidationSchema: yup.object({
        Contact: yup.object({
          CleaningQuote: yup.object({}),
          ServiceInterval: yup.string().required('Please indicate how often you would like your home cleaned').label('Type of Service'),
          PropertyType: yup.string().required('Please indicate the type of your property').label('Type of Service'),
          PropertySqft: yup.string().required('Please indicate the above-grade square footage of your property').label('Square Footage'),
          FlooringType: yup.string().required('Please select a floor type').label('Flooring Type')
        })
      })
    }
  ]
};
