import { Input } from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { InputFieldProps } from './InputFieldsProps';

type TextFieldProps = InputFieldProps<string> & {
  mask?: string;
};

export const TextField = (props: TextFieldProps) => {
  const methods = useFormContext();

  const renderMaskedInput = () => {
    return (
      <Controller
        name={props.name}
        control={methods.control}
        render={() => {
          return (
            <Input
              mask={props.mask}
              {...methods.register(props.name)}
              alwaysShowMask={true}
              as={ReactInputMask}
              disabled={methods.formState.isSubmitting}
            />
          );
        }}></Controller>
    );
  };

  const renderNormalInput = () => {
    return (
      <Input
        disabled={methods.formState.isSubmitting}
        {...methods.register(props.name)}
        defaultValue={props.defaultValue}
      />
    );
  };

  return props.mask ? renderMaskedInput() : renderNormalInput();
};
