import { FieldValues, UseFormReturn } from 'react-hook-form';
import { WizardStep } from '../WizardGenerator/models/WizardDefinition';
import { getAxios } from '../axios';
import { IsDevelopment } from '../config';

export const onHubspotFunnelForward = async (exitingStep: WizardStep, newStep: WizardStep | undefined, formData: any, formDataAccessor: UseFormReturn<FieldValues, any, undefined>) => {
  const dealStageId = IsDevelopment ? exitingStep.DevDealStageId : exitingStep.DealStageId;
  await sendRequest(dealStageId, formData, formDataAccessor, newStep === undefined);
};

export const sendFormDataToHubspot = async (formData: any, formDataAccessor: UseFormReturn<FieldValues, any, undefined>) => {
  //dont set a stage when going backwards
  await sendRequest(undefined, formData, formDataAccessor, false);
};

async function sendRequest(dealStageId: string | undefined, formData: any, formDataAccessor: UseFormReturn<FieldValues, any, undefined>, updateContact: boolean) {
  let url = `/crmsync?updateContact=${updateContact}`;

  if (formData['contactId']) {
    url += `&contactId=${formData['contactId']}`;
  }
  if (formData['dealId']) {
    url += `&existingDealId=${formData['dealId']}`;
  }
  if (dealStageId) {
    url += '&stageId=' + dealStageId;
  }

  const dealResponse = await getAxios().post(url, formData);
  formDataAccessor.setValue('dealId', dealResponse.data.dealId);
  formDataAccessor.setValue('contactId', dealResponse.data.contactId);
}
