import { useQuery } from 'react-query';
import { getEntityDesignerAxios } from '../../entityDesignerAxios';
import { EntityGenerationResponse } from '../models/EntityGenerationResponse';

const getWizardEntityModel = async (entityName: string): Promise<EntityGenerationResponse> => {
  return (await getEntityDesignerAxios().get(`/EntityGeneration/${entityName}`)).data;
};

export const useEntityModelQuery = (entityName: string) => {
  return useQuery(['entityModel', entityName], () => getWizardEntityModel(entityName));
};
