import { ChevronUpIcon } from '@chakra-ui/icons';
import { Flex, HStack, Heading } from '@chakra-ui/react';
import { QuotePrice } from './QuotePrice';

type QuoteBasePriceProps = {
  productName: string;
  price: number;
  onCollapseToggle: () => void;
  isCollapsed: boolean;
};

export const QuoteBasePrice = ({ productName, price, onCollapseToggle }: QuoteBasePriceProps) => {
  return (
    <Flex margin={'5px'} padding={{ base: '0', lg: '5px 10px 10px 10px' }} justifyContent={'space-between'} cursor={'pointer'} onClick={onCollapseToggle}>
      <Heading size={{ base: 'xs' }}>{productName}</Heading>
      <HStack>
        <QuotePrice size={['xs', 'xs', 'xs', 'md']} startingPrice={price} />
        <ChevronUpIcon boxSize={{ base: 5, lg: 7 }} onClick={onCollapseToggle} />
      </HStack>
    </Flex>
  );
};
