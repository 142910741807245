import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconCheckboxQuantityConfig } from '../../../lib/WizardGenerator/models/WizardDefinition';
import { InputFieldProps } from '../InputFieldsProps';
import { IconInputBox } from './IconInputBox';

type IconCheckboxQuantityFieldProps = InputFieldProps<string> & {
  config: IconCheckboxQuantityConfig;
  initialQuantity?: number;
  onSelectedChange?: (newValue: boolean) => void;
  onQuantityChange: (newQuantity: number) => void;
  showAddIcons?: boolean;
  itemKey: string;
};

export const IconCheckboxQuantityField = (props: IconCheckboxQuantityFieldProps) => {
  const [selected, setSelected] = useState(false);
  const methods = useFormContext();

  useEffect(() => {
    const initialValue = methods.getValues(`${props.name}.Selected`);
    setSelected(!!initialValue);
  }, []);

  const changeSelected = (newState: boolean) => {
    setSelected(newState);
    methods.setValue(`${props.name}.Key`, props.itemKey);
    methods.setValue(`${props.name}.Selected`, newState);
    if (!props.config.ShowQuantity) {
      methods.setValue(`${props.name}.Quantity`, newState ? 1 : 0);
    }
  };

  const onSelect = useCallback(() => {
    const newState = !selected;
    changeSelected(newState);
    props.onSelectedChange(newState);
  }, [selected]);

  return (
    <Flex>
      <Box>
        <IconInputBox
          min={props.config.Min}
          max={props.config.Max}
          onClick={onSelect}
          selected={selected}
          defaultAmount={props.initialQuantity}
          iconConfig={props.config.Icon}
          quantityFieldName={props.config.ShowQuantity ? props.config.QuantityName : undefined}
          showAddIcons={props.showAddIcons}
          onQuantityChange={props.onQuantityChange}
        />
      </Box>
    </Flex>
  );
};
