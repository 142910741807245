import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, HStack, Text } from '@chakra-ui/react';
import { TrashBin } from '../../../Icons';
import { QuotePrice } from './QuotePrice';

type QuoteAddonLineProps = {
  name: string;
  formKey: string;
  canRemove: boolean;
  quantity: number;
  showQuantity: boolean;
  price: number;
  color: string;
  onRemoveAddons: (name: string) => void;
};

export const QuoteAddonLine = (props: QuoteAddonLineProps) => {
  const lineColor = `${props.color}.15`;
  return (
    <Box padding={{ base: '4px 35px 4px 10px', lg: '8px 45px 8px 20px' }} backgroundColor={lineColor}>
      <Flex justifyContent={'space-between'}>
        <HStack>
          <ArrowForwardIcon boxSize={5} />
          <Text fontSize={{ base: 'sm', lg: 'md' }}>
            {props.showQuantity && props.quantity} {props.name}
          </Text>
        </HStack>
        <HStack
          onClick={() => {
            props.onRemoveAddons(props.formKey);
          }}>
          <QuotePrice startingPrice={props.price} />
          {props.canRemove && props.formKey && <TrashBin w={'20px'} h={'20px'} cursor={'pointer'} />}
        </HStack>
      </Flex>
    </Box>
  );
};
