import { Box, Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconCheckboxConfig } from '../../../lib/WizardGenerator/models/WizardDefinition';
import { InputFieldProps } from '../InputFieldsProps';
import { IconInputBox } from './IconInputBox';
type IconCheckboxFieldProps = InputFieldProps<string> & {
  config: IconCheckboxConfig;
};

export const IconCheckboxField = (props: IconCheckboxFieldProps) => {
  const methods = useFormContext();
  const [selected, setSelected] = useState<boolean>(false);
  useEffect(() => {
    const selected = methods.getValues(props.name) == true;
    setSelected(selected);
  });

  const onSelected = useCallback(() => {
    const isSelected = !selected;
    setSelected(!selected);
    methods.setValue(props.name, isSelected ? true : undefined);
  }, [selected]);

  return (
    <Box>
      <Flex gap={3}>
        <Box>
          <IconInputBox selected={selected} onClick={onSelected} iconConfig={props.config.Icon} />
        </Box>
      </Flex>
    </Box>
  );
};
