import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { InputFieldProps } from './InputFieldsProps';

type NumericFieldProps = InputFieldProps<string> & {
  min?: number;
  max?: number;
  step?: number;
};

export const NumericField = (props: NumericFieldProps) => {
  const methods = useFormContext();

  return (
    <NumberInput step={props.step} min={props.min} max={props.max}>
      <NumberInputField
        disabled={methods.formState.isSubmitting}
        {...methods.register(props.name)}
        defaultValue={props.defaultValue}
      />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
