import * as yup from 'yup';
import { PropertyDisplayType } from '../../enums/form/PropertyDisplayType';
import { onHubspotFunnelForward, sendFormDataToHubspot } from '../../lib/Hubspot/hubspot';
import { AddressSelectorConfig, IconConfig, IconQuantityConfig, WizardDefinition } from '../../lib/WizardGenerator/models/WizardDefinition';
import {
  Biweekly,
  Blinds,
  Bungalow,
  Carpet,
  CeilingFan,
  Chandelier,
  ChangeBedding,
  CouchVacuum,
  DeepClean,
  DustHighReachSpots,
  FourPieceBath,
  FourStoreySplit,
  Fridge,
  GasRange,
  GlassRailings,
  GlassShower,
  GlassWalls,
  HalfBath,
  HardSurfaceLevels,
  InsideOven,
  InteriorWindows,
  Kid,
  LaunderBedding,
  LoadUnloadDishwasher,
  MoveIn,
  MoveOut,
  OtherHome,
  OversizedRangeHood,
  Pets,
  SplitLevel,
  ThreePieceBath,
  ThreeStoreyHome,
  TwoStorey,
  VacationCondo,
  WetBar
} from './Icons';
import { QuoteStep } from './steps/custom/Quote/QuoteStep';

const addressConfig: AddressSelectorConfig = {
  CityNameField: 'Contact.CleaningQuote.City',
  ProvinceField: 'Contact.CleaningQuote.Province',
  AddressField: 'Contact.CleaningQuote.Address',
  PostalCodeField: 'Contact.CleaningQuote.PostalCode',
  AddressSelectedField: 'Contact.CleaningQuote.HasSelectedAddress'
};

export const AddonIconMappings = {
  OvenCleaning: InsideOven,
  InteriorWindows: InteriorWindows,
  Blinds: Blinds,
  'Dusting High Reach Spots': DustHighReachSpots,
  UnloadLoadDishwasher: LoadUnloadDishwasher,
  FridgeClean: Fridge,
  LaunderBedding: LaunderBedding,
  ChangeBedding: ChangeBedding,
  CeilingFanDusting: CeilingFan,
  CouchVacuum: CouchVacuum,
  WetBar: WetBar
};

const serviceIntervalIcons: { [key: string]: IconConfig } = {
  Recurring: { Icon: Biweekly, DisplayText: 'Recurring' },
  'Deep Clean': { Icon: DeepClean, DisplayText: 'Deep Clean' },
  'Move Out': { Icon: MoveOut, DisplayText: 'Move Out' },
  'Move In': { Icon: MoveIn, DisplayText: 'Move In' }
};

const homeTypeIcons: { [key: string]: IconConfig } = {
  Bungalow: { Icon: Bungalow, DisplayText: 'Bungalow' },
  'Two Storey': { Icon: TwoStorey, DisplayText: 'Two Storey' },
  'Three Storey': { Icon: ThreeStoreyHome, DisplayText: 'Three Storey' },
  'Three Level Split': { Icon: SplitLevel, DisplayText: 'Three Level Split' },
  'Four Level Split': { Icon: FourStoreySplit, DisplayText: 'Four Level Split' },
  'Condo Or Apartment': { Icon: VacationCondo, DisplayText: 'Condo Or Apartment' },
  Other: { Icon: OtherHome, DisplayText: 'Other' }
};

const flooringIconTypes: { [key: string]: IconConfig } = {
  'Hard Surfaces': { Icon: HardSurfaceLevels, DisplayText: 'Hard Surfaces' },
  Carpet: { Icon: Carpet, DisplayText: 'Carpet' }
};

const halfBathConfig: IconQuantityConfig = {
  Icon: { Icon: HalfBath, DisplayText: 'Half Bath' },
  min: 0,
  max: 10
};

const glassShowerConfig: IconQuantityConfig = {
  Icon: { Icon: GlassShower, DisplayText: 'Glass Showers' },
  min: 0,
  max: 10
};

const threePieceBathConfig: IconQuantityConfig = {
  Icon: { Icon: ThreePieceBath, DisplayText: '3 Piece Bath' },
  min: 0,
  max: 10
};

const fourPieceBathConfig: IconQuantityConfig = {
  Icon: { Icon: FourPieceBath, DisplayText: '4 Piece Bath' },
  min: 0,
  max: 10
};

const petConfig: IconQuantityConfig = {
  Icon: { Icon: Pets, DisplayText: 'Furry Pets' },
  min: 0,
  max: 10
};

const kidConfig: IconQuantityConfig = {
  Icon: { Icon: Kid, DisplayText: 'Kids' },
  min: 0,
  max: 10
};

export const FunnelDefinition: WizardDefinition = {
  onStepBackward: sendFormDataToHubspot,
  onStepForward: onHubspotFunnelForward,
  ProgressSteps: [
    {
      Name: 'About You'
    },
    {
      Name: 'Your Cleaning'
    },
    {
      Name: 'Your Home'
    },
    {
      Name: 'Features'
    },
    {
      Name: 'Addons'
    },
    {
      Name: 'Quote'
    },
    {
      Name: 'Booking'
    }
  ],
  Steps: [
    {
      Header: 'About You',
      DealStageId: '138404262',
      DevDealStageId: '141932649',
      Subheader: '',
      StepName: 'About You',
      ProgressStepIndex: 0,
      Questions: [
        { Key: 'Contact.FirstName', Label: 'First Name' },
        { Key: 'Contact.LastName', Label: 'Last Name' },
        { Key: 'Contact.Email', Label: 'Email', HelpText: 'Your email address' },
        { Key: 'Contact.Telephone', Label: 'Phone Number', Mask: '(999) 999-9999' }
      ],

      ValidationSchema: yup.object({
        Contact: yup.object({
          FirstName: yup.string().required('Please enter your first name'),
          LastName: yup.string().required('Please enter your last name'),
          Telephone: yup
            .string()
            .required('Please enter your phone number')
            .matches(new RegExp(/^\(\d{3}\) \d{3}-\d{4}$/), 'Please enter your phone number'),
          Email: yup.string().required('Please enter an email address').email('Please enter a valid email address'),
          CleaningQuote: yup.object({})
        })
      })
    },
    {
      Header: 'About Your Cleaning',
      Subheader: '',
      DealStageId: '138562142',
      DevDealStageId: '141932650',
      StepName: 'Your Cleaning',
      ProgressStepIndex: 1,
      Questions: [
        {
          Key: 'Contact.CleaningQuote.ServiceInterval',
          Label: 'Type of Service',
          PropertyDisplayType: PropertyDisplayType.IconRadio,
          PropertyDisplayTypeConfig: { IconValueMapping: serviceIntervalIcons }
        }
      ],
      ValidationSchema: yup.object({
        Contact: yup.object({
          CleaningQuote: yup.object({
            ServiceInterval: yup.string().required('Please indicate how often you would like your home cleaned').label('Type of Service')
          })
        })
      })
    },
    {
      Header: 'About Your Home',
      StepName: 'Your Home',
      DealStageId: '138562142',
      DevDealStageId: '141932650',
      ProgressStepIndex: 2,
      ValidationSchema: yup.object({
        Contact: yup.object({
          CleaningQuote: yup.object({
            PropertyType: yup.string().required('Please indicate the type of your property').label('Type of Service')
          })
        })
      }),
      Questions: [
        {
          Key: 'Contact.CleaningQuote.PropertyType',
          Label: 'What type of home do you have?',
          PropertyDisplayType: PropertyDisplayType.IconRadio,
          PropertyDisplayTypeConfig: { IconValueMapping: homeTypeIcons }
        }
      ]
    },
    {
      Header: 'About Your Home',
      StepName: 'Home Details',
      DealStageId: '138562142',
      DevDealStageId: '141932650',
      ProgressStepIndex: 2,
      Questions: [
        {
          Key: 'Contact.CleaningQuote.PropertySqft',
          Label: 'What is the square footage of your home (excluding the basement)?',
          NumericMin: 500,
          NumericMax: 10000,
          NumericStep: 100
        },
        {
          Key: 'Contact.CleaningQuote.PropertyLevels',
          Label: 'How many levels does your home have?',
          NumericMin: 1,
          NumericMax: 10,
          NumericStep: 1,
          HideIfProperty: 'Contact.CleaningQuote.PropertyType',
          HideIfValue: ['Bungalow', 'Two Storey', 'Three Storey', 'Three Level Split', 'Four Level Split']
        },
        {
          Key: 'Contact.CleaningQuote.IncludeBasement',
          Label: 'Would you like the basement cleaned?',
          HideIfProperty: 'Contact.CleaningQuote.PropertyType',
          HideIfValue: ['Condo Or Apartment']
        },
        {
          Key: 'Contact.CleaningQuote.BasementFlooringType',
          Label: 'Is your basement hard surface flooring or fully carpeted?',
          PropertyDisplayType: PropertyDisplayType.IconRadio,
          PropertyDisplayTypeConfig: { IconValueMapping: flooringIconTypes },
          HideIfProperty: 'Contact.CleaningQuote.IncludeBasement',
          HideIfValue: [false]
        }
      ],
      ValidationSchema: yup.object({
        Contact: yup.object({
          CleaningQuote: yup.object({
            PropertySqft: yup.string().required('Please indicate the above-grade square footage of your property').label('Square Footage')
            //IncludeBasement: yup.string()..required("Please indicate if you would like the basement cleaned").label("Include Basement"),
          })
        })
      })
    },
    {
      Header: 'About Your Home',
      StepName: 'Bathrooms',
      DealStageId: '138562142',
      DevDealStageId: '141932650',
      ProgressStepIndex: 2,
      Questions: [
        {
          Key: 'Contact.CleaningQuote.FourPieceBathroomCount',
          Label: 'How many bathrooms do you want cleaned?',
          PropertyDisplayType: PropertyDisplayType.IconQuantity,
          PropertyDisplayTypeConfig: fourPieceBathConfig,
          RenderWith: [
            {
              Key: 'Contact.CleaningQuote.ThreePieceBathroomCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: threePieceBathConfig
            },
            {
              Key: 'Contact.CleaningQuote.HalfBathroomCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: halfBathConfig
            },
            {
              Key: 'Contact.CleaningQuote.GlassShowerCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: glassShowerConfig
            }
          ]
        }
      ],
      ValidationSchema: yup.object()
    },
    {
      Header: 'About Your Home',
      StepName: 'Flooring',
      DealStageId: '138562142',
      DevDealStageId: '141932650',
      ProgressStepIndex: 2,
      Questions: [
        {
          Key: 'Contact.CleaningQuote.FlooringType',
          Label: 'Are your bedrooms hard surface flooring or fully carpeted?',
          PropertyDisplayType: PropertyDisplayType.IconRadio,
          PropertyDisplayTypeConfig: { IconValueMapping: flooringIconTypes }
        }
      ],
      ValidationSchema: yup.object({
        Contact: yup.object({
          CleaningQuote: yup.object({
            FlooringType: yup.string().required('Please select a floor type').label('Flooring Type')
          })
        })
      })
    },
    {
      Header: 'About Your Home',
      StepName: 'Munchkins',
      DealStageId: '138562143',
      DevDealStageId: '141932651',
      ProgressStepIndex: 2,
      Questions: [
        {
          Key: 'Contact.CleaningQuote.PetCount',
          Label: 'How many munchkins do you have?',
          PropertyDisplayType: PropertyDisplayType.IconQuantity,
          PropertyDisplayTypeConfig: petConfig,
          RenderWith: [
            {
              Key: 'Contact.CleaningQuote.KidCount',
              PropertyDisplayType: PropertyDisplayType.IconQuantity,
              PropertyDisplayTypeConfig: kidConfig
            }
          ]
        }
      ],
      ValidationSchema: yup.object()
    },

    {
      Header: 'Special Features',
      StepName: 'Features',
      DealStageId: '138562144',
      DevDealStageId: '141932652',
      ProgressStepIndex: 3,
      Questions: [
        {
          Key: 'Contact.CleaningQuote.HasGlassRailings',
          Label: 'Does your home have any of these special features?',
          PropertyDisplayType: PropertyDisplayType.IconCheckbox,
          PropertyDisplayTypeConfig: { Icon: { Icon: GlassRailings, DisplayText: 'Glass Railings' } },
          RenderWith: [
            {
              Key: 'Contact.CleaningQuote.HasGlassWallsDoors',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: GlassWalls, DisplayText: 'Glass Walls/Doors' } }
            },
            {
              Key: 'Contact.CleaningQuote.HasOversizedChandeliers',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: Chandelier, DisplayText: 'Hanging Light Fixtures' } }
            },
            {
              Key: 'Contact.CleaningQuote.HasOversizedHoodFan',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: OversizedRangeHood, DisplayText: 'Oversized Hood Fan' } }
            },
            {
              Key: 'Contact.CleaningQuote.HasProfessionalGasRange',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: GasRange, DisplayText: 'Pro. Gas Range' } }
            },
            {
              Key: 'Contact.CleaningQuote.HasWetBar',
              PropertyDisplayType: PropertyDisplayType.IconCheckbox,
              PropertyDisplayTypeConfig: { Icon: { Icon: WetBar, DisplayText: 'Wet Bar' } }
            }
          ]
        }
      ],
      ValidationSchema: yup.object()
    },
    {
      Header: 'Your cleaning quote',
      StepName: 'Quote',
      DealStageId: '138562146',
      DevDealStageId: '141932654',
      ProgressStepIndex: 5,
      Subheader: '',
      Questions: [],
      ValidationSchema: yup.object(),
      CustomRenderer: QuoteStep,
      NextButtonTextOverride: 'Book'
    },
    {
      Header: 'Book your cleaning',
      StepName: 'Booking',
      ProgressStepIndex: 6,
      DealStageId: '138562147',
      DevDealStageId: '141932655',
      Subheader: '',
      Questions: [
        {
          Key: 'Contact.CleaningQuote.AddressSearchString',
          Label: 'Address',
          PropertyDisplayType: PropertyDisplayType.AddressSelector,
          PropertyDisplayTypeConfig: addressConfig
        },
        { Key: 'Contact.CleaningQuote.HasSelectedAddress', PropertyDisplayType: PropertyDisplayType.Hidden },
        { Key: 'Contact.CleaningQuote.UnitNumber', Label: 'Unit Number' },
        { Key: 'Contact.CleaningQuote.Address', PropertyDisplayType: PropertyDisplayType.Hidden },
        { Key: 'Contact.CleaningQuote.City', PropertyDisplayType: PropertyDisplayType.Hidden },
        { Key: 'Contact.CleaningQuote.Province', PropertyDisplayType: PropertyDisplayType.Hidden },
        { Key: 'Contact.CleaningQuote.PostalCode', PropertyDisplayType: PropertyDisplayType.Hidden },
        {
          Key: 'Contact.CleaningQuote.InitialCleaningDate',
          Label: 'What is your preferred deep clean date?',
          PropertyDisplayType: PropertyDisplayType.DatePicker
        },
        {
          Key: 'Contact.CleaningQuote.RecurringDay',
          Label: 'What is your preferred recurring clean day?',
          HideIfProperty: 'Contact.CleaningQuote.ServiceInterval',
          HideIfValue: ['Move In', 'Move Out', 'Deep Clean']
        },
        { Key: 'Contact.CleaningQuote.PreferredTimeOfDay', Label: 'What is your preferred time of day?' },
        {
          Key: 'Contact.CleaningQuote.PreferredRecurringInterval',
          Label: 'What is your preferred recurring service?',
          HideIfProperty: 'Contact.CleaningQuote.ServiceInterval',
          HideIfValue: ['Move In', 'Move Out', 'Deep Clean']
        }
      ],
      ValidationSchema: yup.object({
        Contact: yup.object({
          CleaningQuote: yup.object({
            HasSelectedAddress: yup
              .boolean()
              .required('Please select your address from the dropdown')
              .isTrue('Please select your address from the dropdown')
              .typeError('Please select your address from the dropdown'),
            InitialCleaningDate: yup.string().required('Please select an initial cleaning date'),
            PreferredTimeOfDay: yup.string().required('Please select a preferred time of day')
          })
        })
      })
    }
  ],
  defaultData: {
    Contact: {
      FirstName: 'Testy',
      LastName: 'McTesterson',
      Email: 'test@example.com',
      Telephone: '(780) 999-2816',
      CleaningQuote: {
        ServiceInterval: 'Recurring',
        PropertyType: 'Three Level Split',
        PropertySqft: '1500',
        PropertyLevels: '',
        IncludeBasement: true,
        BasementFlooringType: 'Hard Surfaces',
        FourPieceBathroomCount: 2,
        ThreePieceBathroomCount: 1,
        HalfBathroomCount: 1,
        GlassShowerCount: 1,
        FlooringType: 'Hard Surfaces',
        PetCount: 2,
        KidCount: 2,
        HasGlassRailings: true,
        HasGlassWallsDoors: true,
        HasOversizedChandeliers: true,
        HasOversizedHoodFan: true,
        HasProfessionalGasRange: true
      }
    }
  }
};
