/* eslint-disable @typescript-eslint/naming-convention */
declare global {
  interface Window {
    FLS_ENTITY_DESIGNER_API_BASE_URL: string | undefined;
    FLS_REACT_APP_API_BASE_URL: string | undefined;
    IsDevelopment: boolean | undefined;
  }
}

export const ENTITY_DESIGNER_API_URL =
  window.FLS_ENTITY_DESIGNER_API_BASE_URL || (import.meta.env.VITE_ENTITY_DESIGNER_API_BASE_URL as string);
export const MOPSPOT_API_URL =
  window.FLS_REACT_APP_API_BASE_URL || (import.meta.env.VITE_REACT_APP_API_BASE_URL as string);
export const IsDevelopment = import.meta.env.VITE_IS_DEVELOPMENT as boolean;

export const ApplicationName = 'mopspot';
