import { Box, Text } from '@chakra-ui/react';
import { CalculatorResponseModel } from '../../../../../lib/Calculator/Models/CalculatorResponseModel';
import { CleaningFrequency } from '../../../../../lib/Calculator/Models/CleaningFrequency';
import { QuoteDetails } from './QuoteDetails';

type CleaningQuoteProps = {
  quotes: CalculatorResponseModel[];
  allCollapsed?: boolean;
  onRemoveAddon: (formKey: string, cleaningFrequency: CleaningFrequency) => void;
};

export const CleaningQuotes = (props: CleaningQuoteProps) => {
  return (
    <Box height={'100%'} padding={{ base: '0', lg: '20px' }} borderRadius={'4px'}>
      {props.quotes.map((value, index) => {
        if (value == null) {
          return;
        }
        const color = index + (1 % 1) === 0 ? 'blue' : 'green';
        return (
          <Box padding={{ base: '5px', lg: '10px 0px 10px 0px' }} key={index}>
            <QuoteDetails
              onRemoveAddon={props.onRemoveAddon}
              canRemoveAddons={value.cleaningFrequency === CleaningFrequency.Biweekly && props.quotes.length > 1}
              collapseDefaultState={props.allCollapsed || index != 0}
              quote={value}
              color={color}
            />
            {index == 1 && (
              <>
                <Text fontSize='md' fontWeight={'bold'}>
                  All cleanings require a deep clean before the classic clean can begin.
                </Text>
              </>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
