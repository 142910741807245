import { Input } from '@chakra-ui/react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useFormContext } from 'react-hook-form';
import { AddressSelectorConfig } from '../../lib/WizardGenerator/models/WizardDefinition';
import { InputFieldProps } from './InputFieldsProps';

type AddressSelectorProps = InputFieldProps<string> & {
  config: AddressSelectorConfig;
};

export const AddressSelector = (props: AddressSelectorProps) => {
  const methods = useFormContext();
  // var southWest = new google.maps.LatLng(50.79986, 115.38666);
  // var northEast = new google.maps.LatLng(51.20645, 113.86917);
  // const bounds = new google.maps.LatLngBounds(southWest, northEast);

  const { ref } = usePlacesWidget<any>({
    apiKey: 'AIzaSyBg0zU9P7a4jxr3K9SCEvxQCy7EOPiDWAM',
    options: {
      componentRestrictions: { country: ['ca'] },
      types: ['address']
    },

    onPlaceSelected: (place) => {
      let street = '';
      let city = '';
      let province = '';
      let postalCode = '';

      place!.address_components!.forEach((component) => {
        if (component.types.includes('street_number')) {
          street = `${component.long_name} `;
        }
        if (component.types.includes('route')) {
          street += component.long_name;
        }
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          province = component.short_name;
        }
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        }
      });
      methods.setValue(props.config.AddressField, street);
      methods.setValue(props.config.CityNameField, city);
      methods.setValue(props.config.ProvinceField, province);
      methods.setValue(props.config.PostalCodeField, postalCode);
      methods.setValue(props.config.AddressSelectedField, true);
    }
  });

  return (
    <Input
      disabled={methods.formState.isSubmitting}
      defaultValue={props.defaultValue}
      {...methods.register(props.name)}
      ref={ref}
    />
  );
};
