import { Box, FormLabel } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ShoppingCart, TrashBin } from '../../../features/funnel/Icons';
import { IconConfig } from '../../../lib/WizardGenerator/models/WizardDefinition';
import { QuantitySelectorField } from './QuantitySelectorField';

type IconInputBoxProps = {
  selected: boolean;
  quantityOnly?: boolean;
  onClick?: () => void;
  iconConfig: IconConfig;
  quantityFieldName?: string;
  onQuantityChange?: (quantity: number) => void;
  min?: number;
  max?: number;
  defaultAmount?: number;
  showAddIcons?: boolean;
};

export const IconInputBox = (props: IconInputBoxProps) => {
  const color = props.selected ? 'green.100' : 'green.100';
  const backgroundColor = props.selected ? 'green.100' : 'white';
  const containerDimensions = { width: '100px', height: '100px' };
  const iconDimensions = { width: '75px', height: '75px' };
  const cursor = props.quantityOnly ? '' : 'pointer';
  const boxShadow = props.selected ? 'inset 0px 3px 6px #0000005E' : '0px 3px 6px #00000033';

  const [showQuantity, setShowQuantity] = useState(false);

  useEffect(() => {
    setShowQuantity((props.selected && !!props.quantityFieldName) || props.quantityOnly);
  }, [props.selected, props.quantityFieldName, props.quantityOnly]);

  return (
    <Box w={containerDimensions.width} textAlign={'center'} cursor={cursor}>
      <Box
        onClick={props.onClick}
        pos={'relative'}
        height={containerDimensions.height}
        w={containerDimensions.width}
        borderColor={color}
        textAlign={'center'}
        borderWidth={'2px'}
        borderStyle={'solid'}
        borderRadius={'50%'}
        boxShadow={boxShadow}
        backgroundColor={backgroundColor}>
        <props.iconConfig.Icon fill={props.selected ? 'white' : ''} margin={'auto'} w={iconDimensions.width} height={iconDimensions.height} paddingTop={'20px'} />
        {props.showAddIcons && (
          <Box pos={'absolute'} w={'30px'} h={'30px'} bottom={'-5px'} right={'2px'}>
            {props.selected ? <TrashBin w={'30px'} h={'30px'} color={color} /> : <ShoppingCart w={'30px'} h={'30px'} color={color} />}
          </Box>
        )}
      </Box>
      <FormLabel paddingTop={'5px'} textAlign={'center'} variant={'icon'} w={'100%'}>
        {showQuantity && (
          <Box w={'100%'} margin={'auto'} textAlign={'center'}>
            <QuantitySelectorField
              notSelectable={props.quantityOnly}
              defaultAmount={props.defaultAmount != undefined ? props.defaultAmount : 0}
              min={props.min}
              max={props.max}
              selected={props.selected}
              name={props.quantityFieldName!}
              onQuantityChange={props.onQuantityChange}
            />
          </Box>
        )}
        {props.iconConfig.DisplayText}
      </FormLabel>
    </Box>
  );
};
