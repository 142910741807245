import { Box, Button, Flex, FormControl, FormLabel } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { NumericField } from '../../components/forms/NumericField';
import { LoadingSpinner } from '../../components/misc/LoadingSpinner';
import { CalculatorResponseModel } from '../../lib/Calculator/Models/CalculatorResponseModel';
import { getQuotesForCleaningType } from '../../lib/Calculator/quoteService';
import { useAllEntitiesQuery } from '../../lib/EntityDesigner/services/getEntities';
import { useEntityModelQuery } from '../../lib/EntityDesigner/services/getEntityModel';
import GenericWizardStep from '../funnel/steps/GenericWizardStep';
import { AddonForm } from '../funnel/steps/custom/AddonForm';
import { CalculatorDefinition } from './CalculatorDefinition';
import { CalculatorQuoteDisplay } from './CalculatorQuoteDisplay';

export const Calculator = () => {
  const entityName = 'Contact';
  const entityDefinition = useEntityModelQuery(entityName);
  const methods = useForm({
    resolver: yupResolver(CalculatorDefinition.Steps[0].ValidationSchema),
    mode: 'all'
  });
  const addonQuery = useAllEntitiesQuery('CleaningAddonDefinition');
  const [calculatorResponse, setCalculatorResponse] = useState<CalculatorResponseModel[]>([]);
  const [isCalculating, setIsCalculating] = useState(false);

  const onCalculate = async () => {
    setIsCalculating(true);
    const quotes = await getQuotesForCleaningType(methods.getValues(), []);
    setCalculatorResponse(quotes);
    setIsCalculating(false);
  };

  return !entityDefinition.data || !addonQuery.data ? (
    <LoadingSpinner />
  ) : (
    <>
      <Flex w={'100%'} gap='10px' justifyContent={'sp'}>
        <Box>
          <FormProvider {...methods}>
            <form>
              <FormControl id={'CleaningQuote.BaseRateOverride'}>
                <FormLabel>Base Rate Override</FormLabel>
                <NumericField name='Contact.CleaningQuote.BaseRateOverride' />
              </FormControl>
              <FormControl id={'CleaningQuote.BaseRateSqftOverride'}>
                <FormLabel>Base Rate Per Sqft Override</FormLabel>
                <NumericField name='Contact.CleaningQuote.BaseRateSqftOverride' />
              </FormControl>
              <Flex>
                {CalculatorDefinition.Steps.map((value, index) => {
                  return (
                    <Box key={index}>
                      {value.CustomRenderer && <value.CustomRenderer stepDefinition={CalculatorDefinition.Steps[index]} properties={entityDefinition.data.properties} />}
                      {!value.CustomRenderer && <GenericWizardStep stepDefinition={CalculatorDefinition.Steps[index]} properties={entityDefinition.data.properties} />}
                    </Box>
                  );
                })}
              </Flex>
              <Flex gap={4} rowGap={'75px'} flexWrap={'wrap'} paddingTop={'75px'}>
                <AddonForm onAddonChange={() => {}} onAddonRemoved={() => {}} />
              </Flex>
            </form>
          </FormProvider>
        </Box>
        <Box>
          <Button onClick={onCalculate}>Calculate</Button>
          {isCalculating ? (
            <LoadingSpinner />
          ) : (
            <div>
              {calculatorResponse.map((quote, index) => {
                return <CalculatorQuoteDisplay quote={quote} index={index} />;
              })}
            </div>
          )}
        </Box>
      </Flex>
    </>
  );
};
