import { useQuery } from 'react-query';
import { getEntityDesignerAxios } from '../../entityDesignerAxios';
import { EntityModel } from '../models/EntityModel';

const getAll = async (entityName: string): Promise<EntityModel[]> => {
  return (await getEntityDesignerAxios().get(`/Entity/${entityName}`)).data;
};

export const useAllEntitiesQuery = (entityName: string) => {
  return useQuery(['entities', entityName], () => getAll(entityName));
};
