import { Box, UseRadioProps, useRadio } from '@chakra-ui/react';

type RadioCardProps = {
  children: React.ReactNode;
  radioProps: UseRadioProps;
  name: string;
  onChange: (value: boolean) => void;
};

export const RadioCard = (props: RadioCardProps) => {
  const { getInputProps, getRadioProps, state } = useRadio(props.radioProps);
  const input = getInputProps();
  const checkbox = getRadioProps();

  checkbox.onChange = () => {
    props.onChange(!state.isChecked);
  };
  return (
    <Box as='label'>
      <input
        {...input}
        onChange={(event) => {
          if (input.onChange) {
            input.onChange(event);
          }
          props.onChange(event.target.value === 'Yes');
        }}
      />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        boxShadow='md'
        width='100px'
        textAlign={'center'}
        _checked={{
          bg: 'green.100',
          color: 'white'
        }}
        _focus={{
          boxShadow: 'outline'
        }}
        px={5}
        py={3}>
        {props.children}
      </Box>
    </Box>
  );
};
