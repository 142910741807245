import Axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

import { ENTITY_DESIGNER_API_URL } from './config';

const authRequestInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  config.headers.Accept = 'application/json';
  return config;
};

const buildHttpClient = ({ baseUrl = '' }): AxiosInstance => {
  const httpClient = Axios.create({
    baseURL: baseUrl
  });
  httpClient.interceptors.request.use(authRequestInterceptor);
  httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status !== 401) {
        const message = error.response?.data?.message || error.message;
        alert("We're sorry, there was an error: " + message);
      } else {
        //window.location.href = '/authentication/login';
      }
      return Promise.reject(error);
    }
  );

  return httpClient;
};

export const entityDesignerAxios = buildHttpClient({ baseUrl: ENTITY_DESIGNER_API_URL });

export const getEntityDesignerAxios = () => {
  return entityDesignerAxios;
};
