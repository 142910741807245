import { Box, Text } from '@chakra-ui/react';

import * as React from 'react';

import { NavBar } from './navBar';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <Box as='section'>
      {location.hostname === 'localhost' && (
        <Box position={'absolute'} bottom={'50'}>
          <Text hideBelow={'2xl'}>2xl</Text>
          <Text hideBelow={'xl'}>xl</Text>
          <Text hideBelow={'lg'}>lg</Text>
          <Text hideBelow={'md'}>md</Text>
          <Text hideBelow={'sm'}>sm</Text>
          <Text hideBelow={'base'}>base</Text>
        </Box>
      )}
      <NavBar />
      <Box paddingTop={['50px', '50px', '175px']} paddingLeft={'10px'} paddingRight={'10px'}>
        {children}
      </Box>
    </Box>
  );
};
