import { Box, Heading, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { QuestionRenderer } from '../../../lib/WizardGenerator/QuestionRenderer';
import { WizardStepProps } from './WizardStepProps';

const GenericWizardStep = (props: WizardStepProps) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} key={props.stepDefinition.StepName}>
      <Box>
        <Heading size={'lg'} marginBottom={'30px'}>
          {props.stepDefinition.Header}
        </Heading>
        {props.stepDefinition.Subheader && <Text>{props.stepDefinition.Subheader}.</Text>}
        <Box>
          {props.stepDefinition.Questions.map((value, key) => {
            return <QuestionRenderer key={key} wizardQuestion={value} entityPropertyModels={props.properties} />;
          })}
        </Box>
      </Box>
    </motion.div>
  );
};

export default GenericWizardStep;
