import { FieldValues, UseFormReturn } from 'react-hook-form';

export const getValidationResults = (name: string, methods: UseFormReturn<FieldValues, any, undefined>) => {
  const keys = name.split('.');
  const result = keys.reduce(
    (obj, key) => (obj && obj[key] !== undefined ? obj[key] : undefined),
    methods.formState.errors as any
  );
  return result;
};
