import {
  Box,
  Input,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import { addDays, format } from 'date-fns';
import React from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { useFormContext } from 'react-hook-form';
import { InputFieldProps } from './InputFieldsProps';

type DatePickerProps = InputFieldProps<string> & NonNullable<unknown>;

export const DateSelector = (props: DatePickerProps) => {
  const methods = useFormContext();

  const { isOpen, onToggle, onClose } = useDisclosure();

  const [selectedDate, setSelectedDate] = React.useState<Date>();

  const openDatePicker = () => {
    onToggle();
  };

  const onSelected = (date: Date | undefined) => {
    setSelectedDate(date);
    const formattedDate = date ? format(date, 'dd-MMM-yyyy') : undefined;
    methods.setValue(props.name, formattedDate);
    onClose();
  };

  const startDate = addDays(new Date(), 1);

  return (
    <Popover isOpen={isOpen} onClose={onClose} closeOnBlur={true} closeOnEsc={true} returnFocusOnClose={false}>
      <PopoverTrigger>
        <Input
          readOnly={true}
          value={selectedDate ? format(selectedDate, 'dd-MMM-yyyy') : ''}
          onClick={openDatePicker}
        />
      </PopoverTrigger>
      <Portal>
        <Box zIndex='popover'>
          <PopoverContent>
            <PopoverCloseButton />
            <DayPicker disabled={{ before: startDate }} mode='single' selected={selectedDate} onSelect={onSelected} />
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  );
};
