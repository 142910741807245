import { Heading } from '@chakra-ui/react';
import { formatCurrency } from '../../../../../lib/util/formatters';

type QuotePriceProps = {
  startingPrice: number;
  endingPrice?: number | undefined;
  size?: string[];
};

export const QuotePrice = ({ size, startingPrice, endingPrice }: QuotePriceProps) => {
  return (
    <Heading size={!size ? ['xs', 'xs', 'xs', 'sm'] : size} fontWeight={'semibold'}>
      {startingPrice == 0 ? (
        <>Included</>
      ) : (
        <>
          {formatCurrency(startingPrice)} {endingPrice && endingPrice != startingPrice && <> - {formatCurrency(endingPrice)}</>}
        </>
      )}
    </Heading>
  );
};
