import { Flex } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconConfig, IconRadioConfig } from '../../../lib/WizardGenerator/models/WizardDefinition';
import { InputFieldProps } from '../InputFieldsProps';
import { IconInputBox } from './IconInputBox';
type IconRadioFieldProps = InputFieldProps<string> & {
  config: IconRadioConfig;
  label?: string;
};

export const IconRadioField = (props: IconRadioFieldProps) => {
  const methods = useFormContext();
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    const selected = methods.getValues(props.name);
    setSelected(selected);
  }, []);

  const onSelected = useCallback(
    (value: string) => {
      setSelected(value);
      methods.setValue(props.name, value);
    },
    [selected]
  );

  return (
    <Flex justifyContent={['space-around', 'unset']} gap={3} flexWrap={'wrap'}>
      {Object.keys(props.config.IconValueMapping).map((key, index) => {
        const value: IconConfig = props.config.IconValueMapping[key];
        return (
          <IconInputBox
            key={index}
            selected={selected === key}
            onClick={() => {
              onSelected(value.DisplayText);
            }}
            iconConfig={value}
          />
        );
      })}
    </Flex>
  );
};
