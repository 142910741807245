import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react';
import { MopspotText } from '../../components/misc/MopspotText';

export const FunnelComplete = () => {
  return (
    <Flex direction={'column'} flex='1' w={'100%'} maxW={'100%'} paddingTop={['30px', '0px']}>
      <Center>
        <Box w={['100%', '100%', '100%', '75%', '75%']}>
          <Heading>Thank you</Heading>
          <Text paddingTop={'10px'} fontSize='xl'>
            Your booking request has been received and we will reach out within the next 24 hours. Get ready to shine!
          </Text>
          <Text paddingTop={'10px'} fontSize='xl'>
            The <MopspotText /> Team
          </Text>
        </Box>
      </Center>
    </Flex>
  );
};
