import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const purple = defineStyle({
  backgroundColor: 'purple.100',
  color: 'white',
  borderRadius: '2px',
  width: ['100px', '200px'],
  _hover: {
    backgroundColor: 'purple.50'
  },
  _active: {
    backgroundColor: 'purple.100'
  }
});

const blue = defineStyle({
  backgroundColor: 'blue.100',
  border: '1px solid ##B6B6B6',
  textAlign: 'center',
  borderRadius: '4px',
  color: 'white',
  _hover: {
    backgroundColor: 'blue.50'
  },
  _active: {
    backgroundColor: 'blue.100'
  }
});

const green = defineStyle({
  backgroundColor: 'green.100',
  border: '1px solid ##B6B6B6',
  textAlign: 'center',
  borderRadius: '4px',
  width: '200px',
  color: 'white',
  _hover: {
    backgroundColor: 'green.50'
  },
  _active: {
    backgroundColor: 'green.100'
  }
});

export const ButtonTheme = defineStyleConfig({
  variants: { purple, blue, green },
  defaultProps: { variant: 'green' }
});
