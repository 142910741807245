import { Box, Button, HStack } from '@chakra-ui/react';
import { FieldValues, UseFormReturn, useFormContext } from 'react-hook-form';
import { useWizardState } from '../../features/funnel/store/WizardStore';
import { WizardStep } from '../../lib/WizardGenerator/models/WizardDefinition';

type WizardNavigationProps = {
  onSubmit: () => void;
  steps: WizardStep[];
  onStepForward?: (exitingStep: WizardStep, newStep: WizardStep | undefined, formData: any, formDataAccessor: UseFormReturn<FieldValues, any, undefined>) => Promise<any>;
  onStepBackward?: (formData: any, formDataAccessor: UseFormReturn<FieldValues, any, undefined>) => Promise<any>;
};

const WizardNavigation = (props: WizardNavigationProps) => {
  const wizardStore = useWizardState();
  const methods = useFormContext();
  return (
    <Box w={'100%'} paddingTop={['20px', '20px', '40px']} paddingBottom={'10px'}>
      <HStack justify={'end'}>
        {wizardStore.currentStep > 0 && (
          <Button
            variant={'blue'}
            onClick={() => {
              const formData = methods.getValues();
              wizardStore.setFormData(formData);
              if (props.onStepBackward) {
                props.onStepBackward(formData, methods);
                wizardStore.backward();
                window.scrollTo(0, 0);
              }
            }}>
            Previous
          </Button>
        )}
        {wizardStore.currentStep < wizardStore.totalSteps && (
          <Button
            onClick={() => {
              methods.trigger().then((isValid) => {
                if (isValid) {
                  const formData = methods.getValues();
                  wizardStore.setFormData(formData);
                  if (props.onStepForward) {
                    props.onStepForward(props.steps[wizardStore.currentStep], props.steps[wizardStore.currentStep + 1], formData, methods);
                    wizardStore.forward();
                    window.scrollTo(0, 0);
                  } else {
                    wizardStore.forward();
                    window.scrollTo(0, 0);
                  }
                }
              });
            }}>
            {props.steps[wizardStore.currentStep].NextButtonTextOverride ? <>{props.steps[wizardStore.currentStep].NextButtonTextOverride}</> : <>Next</>}
          </Button>
        )}
        {wizardStore.currentStep === wizardStore.totalSteps && (
          <Button
            variant={'green'}
            onClick={() => {
              const formData = methods.getValues();
              methods.trigger().then((valid) => {
                if (valid) {
                  if (props.onStepForward) {
                    props.onStepForward(props.steps[wizardStore.currentStep], undefined, formData, methods).then(() => {
                      props.onSubmit();
                      window.scrollTo(0, 0);
                    });
                  } else {
                    props.onSubmit();
                    window.scrollTo(0, 0);
                  }
                }
              });
            }}>
            Submit
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default WizardNavigation;
