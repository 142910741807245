import { Center, Heading, Spinner } from '@chakra-ui/react';

type LoadingSpinnerProps = {
  text?: string;
};
export const LoadingSpinner = (props: LoadingSpinnerProps) => {
  return (
    <Center paddingTop={['30px', '20px']}>
      <Spinner display={'block'} thickness='4px' speed='0.65s' emptyColor='gray.200' color='green.100' size='xl' />
      <br />
      <Heading display={'block'} size='md'>
        &nbsp; {props.text ?? 'Loading...'}
      </Heading>
    </Center>
  );
};
