import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { CalculatorResponseLineItemModel } from '../../lib/Calculator/Models/CalculatorResponseLineItemModel';
import { CalculatorResponseModel } from '../../lib/Calculator/Models/CalculatorResponseModel';
import { PriceRange } from '../../lib/Calculator/Models/PriceRange';
import { formatCurrency } from '../../lib/util/formatters';

type CalculatorQuoteDisplayProps = {
  quote: CalculatorResponseModel;
  index: number;
};

const renderTopQuoteLine = (productName: string, amount: number, color: string, baseRateItems: Array<CalculatorResponseLineItemModel>) => {
  return (
    <>
      <Box backgroundColor={color} color={'white'} borderWidth={{ base: '0', md: '1px' }} p={{ base: '2', md: '4' }} borderRadius='lg'>
        <Stack justify='space-between' direction={'row'} spacing='5'>
          <HStack spacing='3'>
            <Box fontWeight={'bold'} fontSize={['sm', 'sm', 'md']}>
              <Text>{productName}</Text>
            </Box>
          </HStack>
          <Stack spacing='3' direction={'row'}>
            <Text fontWeight={'bold'} fontSize={['sm', 'sm', 'md']}>
              {formatCurrency(amount)}
            </Text>
          </Stack>
        </Stack>
      </Box>
      <b>Base Rate Items:</b>
      {baseRateItems.map((value) => {
        return renderAddonLine(value, color);
      })}
      <b>Other Items:</b>
    </>
  );
};

const renderTotalLine = (productName: string, amount: PriceRange, color: string) => {
  return (
    <Box backgroundColor={color} color={'white'} borderWidth={{ base: '0', md: '1px' }} p={{ base: '2', md: '4' }} borderRadius='lg'>
      <Stack justify='space-between' direction={'row'} spacing='5'>
        <HStack spacing='3'>
          <Box fontWeight={'bold'} fontSize={['sm', 'sm', 'md']}>
            <Text>{productName} Total</Text>
          </Box>
        </HStack>
        <Stack spacing='3' direction={'row'}>
          <Text fontWeight={'bold'} fontSize={['sm', 'sm', 'md']}>
            {formatCurrency(amount.startingPrice)} {amount.startingPrice != amount.endingPrice && formatCurrency(amount.endingPrice)}
          </Text>
        </Stack>
      </Stack>
    </Box>
  );
};

const renderAddonLine = (item: CalculatorResponseLineItemModel, color: string) => {
  return (
    <Box marginLeft={'10px'} borderWidth={{ base: '0', md: '1px' }} borderColor={color} p={{ base: '0', md: '2' }} borderRadius='lg'>
      <Stack justify='space-between' direction={'row'} spacing='5'>
        <HStack spacing='2'>
          <Box fontSize='xs'>
            <Text fontSize={'md'}>
              + {item.quantity} {item.item}
            </Text>
          </Box>
        </HStack>
        <Stack spacing='3' direction={'row'}>
          <Text>{formatCurrency(item.amount)}</Text>
        </Stack>
      </Stack>
      <Text> ItemPrice: {item.originalPrice} </Text>
      <Text>PerSqft: {item.originalPricePerSqft} </Text>
      <Text>SqftUsed: {item.calculatedSquareFootageUsed}</Text>
      <Text>Multiplier: {item.multiplierApplied}</Text>
    </Box>
  );
};

export const CalculatorQuoteDisplay = (props: CalculatorQuoteDisplayProps) => {
  const color = props.index + (1 % 1) === 0 ? 'green.100' : 'blue.100';
  return (
    <Stack spacing='2' paddingBottom={'40px'}>
      <Stack spacing='1'>
        {/* <Text textStyle="sm" fontWeight={'bold'} color="fg.muted">
                {props.quote.productName}
            </Text> */}
        {props.quote.productName === 'Deep Clean' && (
          <Text fontSize='md' fontWeight={'bold'}>
            All cleanings require a deep clean before the classic clean can begin.
          </Text>
        )}
      </Stack>
      {props.quote.lineItems.map((value, index) => {
        return (
          <React.Fragment key={index}>
            {value.isPrimaryItem ? renderTopQuoteLine(props.quote.productName, value.amount, color, props.quote.baseRateItems) : renderAddonLine(value, color)}
          </React.Fragment>
        );
      })}
      {renderTotalLine(props.quote.productName, props.quote.totalCost, color)}
    </Stack>
  );
};
