import { Box, Flex } from '@chakra-ui/react';
import { IconQuantityConfig } from '../../../lib/WizardGenerator/models/WizardDefinition';
import { InputFieldProps } from '../InputFieldsProps';
import { IconInputBox } from './IconInputBox';
type IconQuantityFieldProps = InputFieldProps<string> & {
  config: IconQuantityConfig;
};

export const IconQuantityField = (props: IconQuantityFieldProps) => {
  return (
    <Box>
      <Flex gap={3}>
        <Box>
          <IconInputBox
            quantityOnly={true}
            selected={false}
            onClick={() => {}}
            min={props.config.min}
            max={props.config.max}
            iconConfig={props.config.Icon}
            quantityFieldName={props.name}
            onQuantityChange={() => {}}
          />
        </Box>
      </Flex>
    </Box>
  );
};
