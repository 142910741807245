import { Box, Flex, Image, Link, useColorModeValue } from '@chakra-ui/react';

import logo from '../../assets/Mopspot_logo_large.png';

export const NavBar = () => {
  return (
    <Box
      bg={'white'}
      color={useColorModeValue('gray.600', 'white')}
      px={0}
      borderBottom={1}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.200', 'gray.900')}
      height={['60px', '60px', '120px']}
      position={'fixed'}
      width={'100%'}
      zIndex={'500'}
      shadow={['0 3px 10px rgba(0,0,0,0.15)', '0 3px 10px rgba(0,0,0,0.15)', '0 3px 25px rgba(0,0,0,0.15)']}>
      <Box width={{ xl: '90vw', lg: '90vw', md: '100vw' }} padding={[0, 0, 0, 10]} height={'100%'} margin={'auto'}>
        <Flex alignItems={'center'} height={'100%'}>
          <Link href='https://www.mopspot.ca'>
            <Image marginTop={'auto'} height={['30px', '30px', '60px']} paddingLeft={['10px', '10px', '0px']} src={logo} alt='Mopspot' />
          </Link>
        </Flex>
      </Box>
    </Box>
  );
};
