import { HStack, useRadioGroup } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { InputFieldProps } from './InputFieldsProps';
import { RadioCard } from './RadioCard';

export const BooleanRadio = (props: InputFieldProps<boolean>) => {
  const methods = useFormContext();
  const options = ['Yes', 'No'];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    defaultValue: methods.getValues(props.name) ? 'Yes' : undefined
  });
  const group = getRootProps();

  const onChange = (value: boolean) => {
    methods.setValue(props.name, value);
  };

  return (
    <HStack {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} radioProps={radio} name={props.name} onChange={onChange}>
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
};
