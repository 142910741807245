/* eslint-disable @typescript-eslint/no-unused-vars */
import { RemovedItem } from '../../features/funnel/steps/custom/Quote/QuoteStep';
import { getAxios } from '../axios';
import { CalculatorResponseModel } from './Models/CalculatorResponseModel';
import { CleaningFrequency } from './Models/CleaningFrequency';
import { PricingRequestModel } from './Models/PricingRequestModel';
import { QuoteCalculatorRequestModel } from './Models/QuoteCalculatorRequestModel';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const calculatePrice = async (formModel: any, cleaningFrequency: CleaningFrequency, removedItems: RemovedItem[]): Promise<CalculatorResponseModel> => {
  const cleaningQuote = formModel.Contact.CleaningQuote;
  const priceRequests = new Array<PricingRequestModel>();
  let isBasementExcluded = false;
  addLineItems(priceRequests, cleaningQuote);
  if (removedItems) {
    const excludedItems = removedItems.filter((item: any) => {
      return item.cleaningFrequency == cleaningFrequency;
    });

    excludedItems.forEach((item: any) => {
      const matchingItem = priceRequests.find((priceRequest: PricingRequestModel) => {
        return priceRequest.key == item.key;
      });
      if (matchingItem) {
        priceRequests.splice(priceRequests.indexOf(matchingItem), 1);
      }
    });

    isBasementExcluded =
      excludedItems.find((item: any) => {
        return item.key == 'basement';
      }) != undefined;
  }

  const quoteRequest: QuoteCalculatorRequestModel = {
    squareFootage: cleaningQuote.PropertySqft,
    totalStories: getTotalStories(cleaningQuote.PropertyType, formModel),
    includeBasement: !isBasementExcluded && cleaningQuote.IncludeBasement,
    serviceInterval: cleaningQuote.ServiceInterval,
    pricingRequests: priceRequests,
    baseRateOverride: cleaningQuote.BaseRateOverride ? cleaningQuote.BaseRateOverride : null,
    baseRateSqftOverride: cleaningQuote.BaseRateSqftOverride ? cleaningQuote.BaseRateSqftOverride : null,
    cleaningFrequency: cleaningFrequency
  };

  const toReturn = await getAxios().post<CalculatorResponseModel>('/calculator', quoteRequest);
  return toReturn.data;
};

const getTotalStories = (propertyType: string, formData: any): number => {
  switch (propertyType) {
    case 'Bungalow':
      return 1;
    case 'Two Storey':
      return 2;
    case 'Three Storey':
    case 'Three Level Split':
      return 3;
    case 'Four Level Split':
      return 4;
    case 'Condo Or Apartment':
    case 'Other':
    default:
      return Number(formData.Contact.CleaningQuote.PropertyLevels);
  }
};

const addLineItems = (priceRequests: PricingRequestModel[], cleaningQuote: any) => {
  priceRequests.push({
    key: 'FourPieceBathroomCount',
    quantity: cleaningQuote.FourPieceBathroomCount
  });

  priceRequests.push({
    key: 'ThreePieceBathroomCount',
    quantity: cleaningQuote.ThreePieceBathroomCount
  });

  priceRequests.push({
    key: 'HalfBathroomCount',
    quantity: cleaningQuote.HalfBathroomCount
  });

  priceRequests.push({
    key: 'GlassShowerCount',
    quantity: cleaningQuote.GlassShowerCount
  });

  priceRequests.push({
    key: 'KidCount',
    quantity: cleaningQuote.KidCount
  });

  priceRequests.push({
    key: 'PetCount',
    quantity: cleaningQuote.PetCount
  });

  if (cleaningQuote.HasGlassRailings) {
    priceRequests.push({
      key: 'HasGlassRailings',
      quantity: 1
    });
  }

  if (cleaningQuote.HasGlassWallsDoors) {
    priceRequests.push({
      key: 'HasGlassWallsDoors',
      quantity: 1
    });
  }

  if (cleaningQuote.HasOversizedChandeliers) {
    priceRequests.push({
      key: 'HasOversizedChandeliers',
      quantity: 1
    });
  }

  if (cleaningQuote.HasOversizedHoodFan) {
    priceRequests.push({
      key: 'HasOversizedHoodFan',
      quantity: 1
    });
  }

  if (cleaningQuote.HasProfessionalGasRange) {
    priceRequests.push({
      key: 'HasProfessionalGasRange',
      quantity: 1
    });
  }
  if (cleaningQuote.CleaningQuoteAddons) {
    cleaningQuote.CleaningQuoteAddons.forEach((addon: any) => {
      if (addon.Selected) {
        priceRequests.push({
          key: addon.Key,
          quantity: addon.Quantity
        });
      }
    });
  }

  if (cleaningQuote.BasementFlooringType !== 'Carpet' && cleaningQuote.IncludeBasement) {
    priceRequests.push({
      key: 'BasementHardSurfaceFloors',
      quantity: 1
    });
  }

  if (cleaningQuote.FlooringType !== 'Carpet') {
    priceRequests.push({
      key: 'HardSurfaceFloors',
      quantity: 1
    });
  }
};
